<template>
  <div>
    <shoppingCart notpopup />
  </div>
</template>

<script>
import shoppingCart from "./components/shoppingCart";
import { mapState } from "vuex";

export default {
  components: {
    shoppingCart,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    if (!this.user) {
      this.$root.notify("Vartotojas neprisijungęs", "warning");
      this.$router.push("/paskyra");
    }
  },
};
</script>

<style></style>
